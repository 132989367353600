const getTemplate = event => `
<div class="modal-header ps-card border-bottom pb-4">
  <div>
    <h4 class="modal-title text-1000 mb-3 mt-3">${event.title}</h4>
    ${
      event.extendedProps.organizer
        ? `<p class="mb-0 fs--1 mt-2 text-gray--500">
        申請人： <span class="text-info">${event.extendedProps.organizer}</span>
      </p>`
        : ''
    }
    ${
      event.extendedProps.tel
        ? `<p class="mb-0 fs--1 mt-1 text-gray--500">
        聯絡電話： <span class="text-info">${event.extendedProps.tel}</span>
      </p>`
        : ''
    }
  </div>
  <button type="button" class="btn p-1 fw-bolder" data-bs-dismiss="modal" aria-label="Close">
    <span class='fas fa-times fs-0'></span>
  </button>

</div>

<div class="modal-body px-card pb-card pt-1 fs--1">
  <div class="mt-4 border-bottom pb-3">
    <h5 class='mb-0 text-800'>借用時間</h5>
    <p class="mb-1 mt-2">
    ${
      window.dayjs &&
      window.dayjs(event.start).format('dddd, MMMM D, YYYY, h:mm A')
    } 
    ${
      event.end
        ? `– ${
            window.dayjs &&
            window
              .dayjs(event.end)
              .subtract(1, 'day')
              .format('dddd, MMMM D, YYYY, h:mm A')
          }`
        : ''
    }
  </p>
  </div>
  ${
    event.extendedProps.purpose
      ? `
        <div class="mt-4 border-bottom pb-3">
          <h5 class='mb-0 text-800'>借用目的</h5>
          <p class="mb-0 mt-2">${event.extendedProps.purpose}</p>
        </div>
      `
      : ''
  }
  ${
    event.extendedProps.activity
      ? `
        <div class="mt-4 border-bottom pb-3">
          <h5 class='mb-0 text-800'>活動內容</h5>
          <p class="mb-0 mt-2">${event.extendedProps.activity}</p>
        </div>
      `
      : ''
  }
  ${
    event.extendedProps.description
      ? `
        <div class="mt-4 ">
          <h5 class='mb-0 text-800'>備註</h5>
          <p class="mb-0 mt-2">${event.extendedProps.description}</p>
        </div>
      `
      : ''
  }
  ${
    event.schedules
      ? `
      <div class="mt-3">
        <h5 class='mb-0 text-800'>Schedule</h5>
        <ul class="list-unstyled timeline mt-2 mb-0">
          ${event.schedules
            .map(schedule => `<li>${schedule.title}</li>`)
            .join('')}
        </ul>
      </div>
      `
      : ''
  }
  </div>
</div>

<div class="modal-footer d-flex justify-content-end px-card pt-0 border-top-0">
  <button type="button" data-bs-dismiss="modal" class="btn btn-primary btn-sm" aria-label="Close">
    關閉　
    <span class="fas fa-angle-right fs--2 ml-1"></span>
  </button>
</div>
`;

export default getTemplate;
